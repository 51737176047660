import { Ref } from "vue";

type Direction = "from" | "to";
interface ErrorObject {
  [key: string]: string | object;
}

interface UseFormErrorsResult {
  errors: Ref<ErrorObject>;
  setErrors: (error: ErrorObject) => void;
  clearErrors: () => void;
  clearError: (name: string, direction?: Direction) => void;
  hasErrors: Ref<boolean>;
}
export const useFormErrors = (): UseFormErrorsResult => {
  const errors = ref<ErrorObject>({});

  function setErrors(error: ErrorObject) {
    Object.keys(error).forEach((key) => {
      const errorValue = error[key];

      if (Array.isArray(errorValue)) {
        [errors.value[key]] = errorValue;
      } else if (typeof errorValue === "object") {
        errors.value[key] = errorValue;
      } else {
        errors.value[key] = errorValue;
      }
    });
  }

  function clearError(name: string, direction?: Direction) {
    if (direction && errors.value && errors.value[`${direction}Requisites`]) {
      const error: any = errors.value[`${direction}Requisites`];
      if (error[name]) {
        delete error[name];
      }
      if (Object.keys(error).length === 0) {
        delete errors.value[`${direction}Requisites`];
      }
    } else {
      delete errors.value[name];
    }
  }

  function clearErrors() {
    errors.value = {};
  }

  const hasErrors = computed(() => {
    return Object.keys(errors.value).length > 0;
  });

  return {
    errors,
    setErrors,
    clearErrors,
    clearError,
    hasErrors,
  };
};
